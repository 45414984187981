import React from 'react';

const MoreKnowledgeBadge = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="109.604"
    height="112.853"
    viewBox="0 0 109.604 112.853"
  >
    <defs>
      <clipPath>
        <rect width="109.604" height="112.853" fill="none" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip-path)">
      <path
        d="M15.343,68.111q.25.361.517.84t.541.975c.184.332.355.658.514.975s.3.6.414.85a8.019,8.019,0,0,1,.875,3.295,5.345,5.345,0,0,1-.656,2.691A7.119,7.119,0,0,1,15.6,79.93a16.619,16.619,0,0,1-3.028,1.805,16.89,16.89,0,0,1-3.193,1.147,7.8,7.8,0,0,1-3.052.17,5.885,5.885,0,0,1-2.713-1.18,7.663,7.663,0,0,1-2.166-2.887c-.09-.189-.2-.448-.34-.775s-.278-.672-.422-1.034S.4,76.47.275,76.139.054,75.547,0,75.358Zm-.418,5.123c-.11-.233-.226-.469-.347-.706A3.669,3.669,0,0,0,14.26,72L3.71,76.979a1.12,1.12,0,0,0,.076.246c.045.113.091.233.144.36s.1.248.143.361.078.191.1.234a2.978,2.978,0,0,0,1.223,1.4,3.449,3.449,0,0,0,1.7.417,6.867,6.867,0,0,0,2-.329,16.38,16.38,0,0,0,2.157-.845,17.128,17.128,0,0,0,1.891-1.041,5.979,5.979,0,0,0,1.434-1.239,2.909,2.909,0,0,0,.351-3.312"
        fill="currentColor"
      />
      <path
        d="M19.678,79.3a1.541,1.541,0,0,1,1.169-.336,1.44,1.44,0,0,1,.988.6,1.532,1.532,0,0,1,.306,1.321A2.3,2.3,0,0,1,21.2,82.15a4.146,4.146,0,0,1-1.443.737,4.081,4.081,0,0,1-1.274.133,3.6,3.6,0,0,1-1.051-.216,4.722,4.722,0,0,1-.74-.342l.537-1.306a2.1,2.1,0,0,0,.906.244,1.684,1.684,0,0,0,.977-.269,1.154,1.154,0,0,1-.143-.859,1.623,1.623,0,0,1,.71-.973"
        fill="currentColor"
      />
      <path
        d="M23.732,82.58l6.261,5.709L28.1,90.363,24.094,86.71l-2.806,3.076L24.919,93.1l-1.892,2.074L19.4,91.861,16.461,95.08,20.539,98.8l-1.892,2.074L12.315,95.1Z"
        fill="currentColor"
      />
      <path
        d="M30.238,98.114l.2-9.131,3.32,1.586-.317,5.215-.378,1.857,1.186-1.472L38.2,92.688l2.971,1.418L34.156,99.8l-.118,9.681-3.211-1.533.189-5.678.356-1.922-1.251,1.5-4.3,3.713-3.036-1.449Z"
        fill="currentColor"
      />
      <path
        d="M42.235,94.568a16.761,16.761,0,0,1,1.936.028,19.878,19.878,0,0,1,2.07.246,11.733,11.733,0,0,1,2.123.576,4.835,4.835,0,0,1,1.758,1.127,4.383,4.383,0,0,1,1.07,1.917,6.671,6.671,0,0,1,.036,2.917,7.33,7.33,0,0,1-.9,2.6,5.007,5.007,0,0,1-1.491,1.584,4.512,4.512,0,0,1-1.878.707,6.619,6.619,0,0,1-2.045-.033l-.311-.055c-.143-.026-.293-.055-.451-.09s-.31-.074-.458-.117a2.637,2.637,0,0,1-.314-.1l-.994,5.748-3.005-.519Zm1.608,8.6a2.973,2.973,0,0,0,.521.177c.258.068.436.111.531.128a3.519,3.519,0,0,0,1.027.03,2.14,2.14,0,0,0,.931-.343,2.381,2.381,0,0,0,.75-.852,4.739,4.739,0,0,0,.479-1.5,3.92,3.92,0,0,0,.03-1.37,2.271,2.271,0,0,0-.4-.966,2.113,2.113,0,0,0-.72-.6,3.173,3.173,0,0,0-.929-.307,5.15,5.15,0,0,0-1.237-.091Z"
        fill="currentColor"
      />
      <path
        d="M53.3,95.987l8.434-.811.269,2.795-5.4.52.4,4.144,4.89-.47.269,2.795-4.891.47.417,4.336,5.493-.527.269,2.795-8.53.819Z"
        fill="currentColor"
      />
      <path
        d="M63.322,95.063A16.745,16.745,0,0,1,65.2,94.007q1.028-.5,1.8-.793a10.14,10.14,0,0,1,1.972-.536,4.58,4.58,0,0,1,1.908.072,4,4,0,0,1,1.681.936,5.2,5.2,0,0,1,1.287,2.035,6.553,6.553,0,0,1,.489,3.4,3.954,3.954,0,0,1-1.317,2.411l1.181.376,5.239,4.938-3.277,1.258-5.15-5.18-1.334.174,2.507,6.532-2.848,1.092Zm5.606,6.095.971-.372a2.123,2.123,0,0,0,1.358-1.26,3.289,3.289,0,0,0-.225-2.209,3.031,3.031,0,0,0-1.089-1.488,1.738,1.738,0,0,0-1.671-.1c-.241.093-.472.191-.693.292a2.437,2.437,0,0,0-.507.3Z"
        fill="currentColor"
      />
      <path
        d="M83.275,87.116l-3.248,2.365,8.322,11.428L85.883,102.7,77.561,91.278l-3.248,2.365-1.652-2.269,8.961-6.528Z"
        fill="currentColor"
      />
      <rect
        width="3.05"
        height="16.945"
        transform="matrix(0.664, -0.748, 0.748, 0.664, 82.427, 83.531)"
        fill="currentColor"
      />
      <path
        d="M99.95,80.563a1.966,1.966,0,0,0-1.373-.258,6,6,0,0,0-1.464.487q-.765.357-1.608.808a8.753,8.753,0,0,1-1.776.712,5.9,5.9,0,0,1-1.958.2,4.988,4.988,0,0,1-2.181-.72,5.265,5.265,0,0,1-1.631-1.367,3.751,3.751,0,0,1-.705-1.591,4.145,4.145,0,0,1,.077-1.741,6.959,6.959,0,0,1,.73-1.785,10.432,10.432,0,0,1,1.354-1.875,6.22,6.22,0,0,1,1.446-1.209l1.861,2.156a4.344,4.344,0,0,0-1.07.795,6.115,6.115,0,0,0-1.082,1.4,2.362,2.362,0,0,0-.387,1.453,1.346,1.346,0,0,0,.735,1.007,1.76,1.76,0,0,0,1.268.2,6.545,6.545,0,0,0,1.443-.5q.764-.358,1.618-.8a9.037,9.037,0,0,1,1.8-.7,6.019,6.019,0,0,1,1.978-.192,4.962,4.962,0,0,1,2.151.7,6,6,0,0,1,1.745,1.432,4.079,4.079,0,0,1,.825,1.7,4.561,4.561,0,0,1-.016,1.9,6.935,6.935,0,0,1-.8,2.024,10.618,10.618,0,0,1-1.558,2.135,6.741,6.741,0,0,1-1.427,1.177L98.063,85.9a7.172,7.172,0,0,0,1.167-.866,5.564,5.564,0,0,0,1.155-1.43q1.2-2.1-.435-3.039"
        fill="currentColor"
      />
      <path
        d="M91,70.383l2.243-8.17,2.708.743-1.434,5.23,4.013,1.1,1.3-4.738,2.707.743-1.3,4.738,4.2,1.154,1.461-5.322,2.707.744-2.267,8.262Z"
        fill="currentColor"
      />
      <path
        d="M14.183,16.686a16.042,16.042,0,0,1,1.193-1.4A18.663,18.663,0,0,1,16.81,13.92a11.272,11.272,0,0,1,1.708-1.206,4.589,4.589,0,0,1,1.891-.6,4.151,4.151,0,0,1,2.05.379,6.28,6.28,0,0,1,2.157,1.74,6.931,6.931,0,0,1,1.353,2.233,4.746,4.746,0,0,1,.257,2.051,4.28,4.28,0,0,1-.621,1.8,6.223,6.223,0,0,1-1.263,1.476c-.048.04-.122.1-.228.195s-.218.181-.34.275-.242.183-.363.266a2.481,2.481,0,0,1-.266.167l3.607,4.21-2.2,1.884Zm7.272,4.037a2.736,2.736,0,0,0,.445-.275c.207-.148.347-.251.416-.311a3.368,3.368,0,0,0,.644-.733,2.031,2.031,0,0,0,.313-.89,2.251,2.251,0,0,0-.17-1.064,4.519,4.519,0,0,0-.807-1.262,3.748,3.748,0,0,0-.987-.852,2.149,2.149,0,0,0-.948-.293,2.019,2.019,0,0,0-.877.16,3.054,3.054,0,0,0-.788.495,4.9,4.9,0,0,0-.816.849Z"
        fill="currentColor"
      />
      <path
        d="M39.924,18.564l-7.631,3.871L25.01,8.077l2.584-1.312,6.077,11.98,5.046-2.56Z"
        fill="currentColor"
      />
      <path
        d="M43.519,1.179,46.188.572,48.5,10.753q.694,3.051-.24,4.7a4.526,4.526,0,0,1-3.131,2.152,4.96,4.96,0,0,1-4.082-.475,6.082,6.082,0,0,1-2.126-3.8l-2.4-10.563,2.826-.643L41.639,12.2a4.966,4.966,0,0,0,1.077,2.455,1.873,1.873,0,0,0,1.8.356,1.739,1.739,0,0,0,1.4-1.13,5.2,5.2,0,0,0-.1-2.631Z"
        fill="currentColor"
      />
      <path
        d="M56.557,12.319a1.875,1.875,0,0,0-.4-1.266A5.8,5.8,0,0,0,55.1,10.044q-.642-.48-1.4-.986a8.323,8.323,0,0,1-1.391-1.169,5.638,5.638,0,0,1-1.047-1.55,4.747,4.747,0,0,1-.373-2.151A4.961,4.961,0,0,1,51.3,2.211,3.536,3.536,0,0,1,52.32.906,3.958,3.958,0,0,1,53.814.191,6.672,6.672,0,0,1,55.637,0,9.978,9.978,0,0,1,57.814.3a5.949,5.949,0,0,1,1.66.671L58.5,3.495a4.1,4.1,0,0,0-1.144-.54,5.844,5.844,0,0,0-1.66-.281A2.244,2.244,0,0,0,54.305,3a1.276,1.276,0,0,0-.516,1.066,1.67,1.67,0,0,0,.4,1.151,6.2,6.2,0,0,0,1.063.987q.642.479,1.4,1a8.6,8.6,0,0,1,1.391,1.192A5.8,5.8,0,0,1,59.09,9.967a4.707,4.707,0,0,1,.374,2.117,5.718,5.718,0,0,1-.42,2.1,3.871,3.871,0,0,1-1.055,1.453,4.345,4.345,0,0,1-1.6.838,6.581,6.581,0,0,1-2.055.237,9.989,9.989,0,0,1-2.486-.35,6.317,6.317,0,0,1-1.625-.67l1.02-2.569a6.779,6.779,0,0,0,1.248.588,5.275,5.275,0,0,0,1.716.329q2.3.069,2.352-1.725"
        fill="currentColor"
      />
      <path
        d="M70.339,2.618c.273.065.571.151.9.264s.661.224,1,.344.668.237.983.36.592.234.832.335a7.635,7.635,0,0,1,2.715,1.767,5.087,5.087,0,0,1,1.251,2.317,6.775,6.775,0,0,1,.02,2.788,15.778,15.778,0,0,1-.987,3.2,16.1,16.1,0,0,1-1.522,2.842A7.394,7.394,0,0,1,73.49,18.9a5.6,5.6,0,0,1-2.666.894,7.244,7.244,0,0,1-3.366-.657c-.184-.075-.428-.186-.733-.33s-.624-.3-.955-.461-.648-.321-.949-.472a7.856,7.856,0,0,1-.691-.375ZM73.305,6.5c-.227-.094-.457-.186-.693-.276a3.408,3.408,0,0,0-.564-.173l-4.269,10.23a1.027,1.027,0,0,0,.21.126c.1.05.212.105.33.161s.227.113.331.164.176.085.218.1a2.833,2.833,0,0,0,1.757.209,3.285,3.285,0,0,0,1.462-.786,6.493,6.493,0,0,0,1.2-1.505,15.449,15.449,0,0,0,.984-1.969,16.459,16.459,0,0,0,.672-1.937,5.72,5.72,0,0,0,.222-1.789A2.765,2.765,0,0,0,73.305,6.5"
        fill="currentColor"
      />
      <path
        d="M84.206,9.281l6.412,4.868L89,16.273l-4.1-3.115-2.392,3.15,3.718,2.824-1.613,2.126-3.719-2.825-2.5,3.3L82.568,24.9l-1.614,2.125L74.47,22.1Z"
        fill="currentColor"
      />
      <path
        d="M88.346,55.929a34.7,34.7,0,1,1-34.7-34.7,34.7,34.7,0,0,1,34.7,34.7"
        fill="currentColor"
      />
      <path
        d="M37.028,57.311a11.777,11.777,0,0,1-3.884-3.069l1.061-1.1a11.026,11.026,0,0,0,3.316,2.614,7.693,7.693,0,0,0,3.429.872,5.081,5.081,0,0,0,3.809-1.5,6.317,6.317,0,0,0,1.459-4.566,6.284,6.284,0,0,0-1.364-4.4,4.536,4.536,0,0,0-3.487-1.477,11.117,11.117,0,0,0-2.842.379l-1.137.341-.909-1.441,10.345-9.7H40.041a5.148,5.148,0,0,0-3.506,1.08A3.89,3.89,0,0,0,35.3,38.439H34.167V35.6q0-3.03,3.221-3.031H50.2V34.27l-9.436,8.868q.264-.038.966-.1t1.459-.057a10.173,10.173,0,0,1,3.847.777,7.494,7.494,0,0,1,3.2,2.483,6.964,6.964,0,0,1,1.289,4.32,6.857,6.857,0,0,1-1.516,4.661,8.068,8.068,0,0,1-3.543,2.426,12.1,12.1,0,0,1-3.847.681,13.024,13.024,0,0,1-5.59-1.023"
        fill="#fff"
      />
      <path
        d="M54.328,45.071q0-13.264,9.853-13.265t9.853,13.265q0,13.265-9.853,13.264T54.328,45.071m13.321,8.792q1.08-2.766,1.08-8.792t-1.08-8.792q-1.08-2.768-3.468-2.767t-3.468,2.767q-1.079,2.766-1.08,8.792t1.08,8.792q1.081,2.768,3.468,2.767t3.468-2.767"
        fill="#fff"
      />
      <path
        d="M35.575,76.88A3.178,3.178,0,0,1,34.4,74.287a3.662,3.662,0,0,1,.619-2.183,3.875,3.875,0,0,1,2.171-1.317,16.314,16.314,0,0,1,4.316-.447h.4a6.192,6.192,0,0,0-.632-3.25,2.091,2.091,0,0,0-1.868-.961,2.708,2.708,0,0,0-1.685.5,3.08,3.08,0,0,0-.947,1.869H35.325a8.435,8.435,0,0,1,.119-1.527,2.257,2.257,0,0,1,.539-1.105q.816-.921,3.948-.921a5.436,5.436,0,0,1,4.013,1.381,5.631,5.631,0,0,1,1.382,4.146v7.105H43.089l-.921-1.184h-.132a2.91,2.91,0,0,1-.737.737,3.788,3.788,0,0,1-2.421.71,5.073,5.073,0,0,1-3.3-.96m5.04-.579a3.224,3.224,0,0,0,.763-.618,2.567,2.567,0,0,0,.527-.869V71.525h-.4A4.359,4.359,0,0,0,38.8,72.2a2.512,2.512,0,0,0-.842,2.091,2.662,2.662,0,0,0,.448,1.659,1.5,1.5,0,0,0,1.263.579,1.893,1.893,0,0,0,.947-.224"
        fill="#fff"
      />
      <path
        d="M48.8,68.629a4.274,4.274,0,0,0-.355-1.986A1.519,1.519,0,0,0,46.984,66h-.263v-.789h2.6a3.6,3.6,0,0,1,1.408.224,1.665,1.665,0,0,1,.8.75,5.471,5.471,0,0,1,3.842-1.238,5.31,5.31,0,0,1,3.751,1.172,4.816,4.816,0,0,1,1.25,3.671v7.79H56.958V69.761a4.251,4.251,0,0,0-.671-2.658,2.211,2.211,0,0,0-1.829-.842,4.21,4.21,0,0,0-1.434.223,2.66,2.66,0,0,0-.987.592l.079.369a3.574,3.574,0,0,1,.1,1.027v9.105H48.8Z"
        fill="#fff"
      />
      <path
        d="M63.616,76.762a1.834,1.834,0,0,1-.526-1.119,14.689,14.689,0,0,1-.1-2.013h.921a3.915,3.915,0,0,0,1.079,2.2,3.193,3.193,0,0,0,2.342.83q2.631,0,2.632-1.791a1.383,1.383,0,0,0-.566-1.131,9,9,0,0,0-1.8-1l-1.369-.579a7.946,7.946,0,0,1-2.526-1.6,3.079,3.079,0,0,1,.54-4.685,6.414,6.414,0,0,1,3.618-.921q3.08,0,4.237,1.079a1.824,1.824,0,0,1,.526,1.1,14.79,14.79,0,0,1,.106,2.027H71.8a3.931,3.931,0,0,0-1.079-2.2,3.2,3.2,0,0,0-2.342-.829,2.491,2.491,0,0,0-1.685.487,1.652,1.652,0,0,0-.553,1.3,1.437,1.437,0,0,0,.461,1.092,5.1,5.1,0,0,0,1.408.855l1.579.659a9.239,9.239,0,0,1,2.8,1.644,3.078,3.078,0,0,1-.631,4.764,7.436,7.436,0,0,1-3.909.907q-3.078,0-4.237-1.078"
        fill="#fff"
      />
      <path
        d="M6.782,67.276a1.5,1.5,0,0,1-1.476-1.243,49.507,49.507,0,0,1-.726-8.456A48.622,48.622,0,0,1,14.644,27.8a1.5,1.5,0,1,1,2.383,1.823A45.646,45.646,0,0,0,7.58,57.577a46.45,46.45,0,0,0,.682,7.942,1.5,1.5,0,0,1-1.221,1.735,1.527,1.527,0,0,1-.259.022"
        fill="currentColor"
      />
      <path
        d="M101.212,59.077a1.5,1.5,0,0,1-1.5-1.5A45.562,45.562,0,0,0,89.59,28.763a1.5,1.5,0,1,1,2.34-1.877,49.192,49.192,0,0,1,10.782,30.691,1.5,1.5,0,0,1-1.5,1.5"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default MoreKnowledgeBadge;
